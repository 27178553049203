import React from 'react';
import LineItem from './LineItem';
import { Button, Col, Container, Row } from "react-bootstrap";


export default function Cart(props) {
    const openCheckout = () => {
        window.open(props.checkout.webUrl, '_self');
    };

    let lineItems = props.checkout.lineItems.map((lineItem) => {
        return (
            <LineItem
                updateQuantityInCart={props.updateQuantityInCart}
                removeLineItemInCart={props.removeLineItemInCart}
                key={lineItem.id.toString()}
                lineItem={lineItem}
            />
        );
    });

    return (
        <Container fluid className={"top-60"}>
            {lineItems}
            <hr className="cart-footer" />
            {
                props.checkout.lineItems.length ? (
                    <>
                        <Row className={"top-10"}>
                            <Col className={"margin-auto"} sm={{ span: 9, offset: 3 }} xs={{ span: 10 }}>
                                <Row>
                                    <Col sm={{ span: 2, offset: 8 }} xs={{ span: 4, offset: 2 }}>
                                        <Row>
                                            <span className={"block width-full text-left"}>Sous total</span>
                                        </Row>
                                    </Col>
                                    <Col sm={{ span: 2, offset: 0 }} xs={{ span: 4, offset: 0 }}>
                                        <span className={"block width-full text-right text-right-mobile"}>{parseFloat(props.checkout.subtotalPrice.amount).toFixed(2)} €</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className={"top-10"}>
                            <Col className={"margin-auto"} sm={{ span: 9 }} xs={{ span: 10 }}>
                                <Row>
                                    <Col sm={{ span: 4, offset: 8 }} xs={{ span: 8, offset: 2 }}>
                                        <Button variant={"dark"} className={"buy-button width-full"}
                                            onClick={openCheckout}>PROCÉDER AU PAIEMENT</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <Container className={"top-60"}>
                        <Row>
                            <span className={"width-full text-center block"}>
                                Désolé, votre panier est vide.<br />Rendez-vous dans la boutique !
                            </span>
                        </Row>
                    </Container>
                )
            }
        </Container>
    )
}
