import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/css/bootstrap.min.css';
import './assets/css/app.css';
import 'react-toastify/dist/ReactToastify.min.css';
import {BrowserRouter} from "react-router-dom";

const renderMethod = module && module.hot ? ReactDOM.render : ReactDOM.hydrate;

renderMethod(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);
