import React, {useState} from "react";
import MenuButton from "./MenuButton";

import {Link} from "react-router-dom";
import {slide as Menu} from 'react-burger-menu'
import {Col, Container, Row} from 'react-bootstrap';

export default function Header(props) {
    const [isOpen, setIsOpen] = useState(false);

    const getNumberCart = () => {
        return props.checkout.lineItems.length ? props.checkout.lineItems.reduce((total, item) => {
            total += item.quantity;
            return total;
        }, 0) : 0;
    };

    const getCartText = () => {
        return `PANIER (${getNumberCart()})`
    };
    const menuDataFirstRow = [
        {
            title: "NOUVEAU",
            icon: "/images/icons/search.svg",
            link: "/",
            position: "Left"
        },
        {title: "CONTACT", link: "/contact", position: "Right"},

    ];

    const menuDataSecondRow = [
        {title: "CONCEPT", link: "/lookbook", position: "Left"},
        {title: "A PROPOS", link: "/about", position: "Right"}
    ];

    const menuDataThirdRow = [
        {title: "BOUTIQUE", link: "/shop#products", position: "Left"},
        {
            title: getCartText(), link: "/cart", position: "Right"
        }
    ];

    const menuDataMobile = [
        {title: "CONCEPT", link: "/lookbook", position: "Left"},
        {title: "BOUTIQUE", link: "/shop", position: "Left"},
        {title: getCartText(), link: "/cart", position: "Right"},
        {title: "A PROPOS", link: "/about", position: "Right"},
        {title: "CONTACT", link: "/contact", position: "Right"},
        {}
    ];

    const preventMotion = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    function openMenu() {
        document.body.classList.add("stop-scrolling");
        document.body.addEventListener("scroll", preventMotion, false);
        document.body.addEventListener("touchmove", preventMotion, false);
        setIsOpen(true);
    }

    function closeMenu() {
        document.body.classList.remove("stop-scrolling");
        document.body.removeEventListener("scroll", preventMotion, false);
        document.body.removeEventListener("touchmove", preventMotion, false);
        setIsOpen(false);
    }

    function renderRowMenu(item, index) {
        return (
            <Col sm={4} key={item.title + index}>
                <MenuButton item={item} index={index}/>
            </Col>
        )
    }

    function renderItemsBurgerMenu(item, index) {
        if (index === 5) {
            return (
                <div key={`renderItemsBurgerMenu-${index}`}>
                    <Row className={"flex"} key={`renderItemsBurgerMenu-${index}`}>
                        <img className="instagram-logo cursor-pointer" onClick={() => {
                            window.open("https://www.instagram.com/fx.vetements/?hl=fr", '_blank')
                        }} src={"/assets/insta.svg"} alt="instagram-logo" height={15} width={30.59}/>
                        <img className="facebook-logo cursor-pointer" onClick={() => {
                            window.open("https://www.facebook.com/fx.vetements", '_blank')
                        }} src={"/assets/fb.svg"} alt="facebook-logo" height={15} width={23.72}/>
                    </Row>
                    <Row className='other-links'>
                        <Link onClick={closeMenu} to='/cgu'>
                            <p>CONDITIONS D’UTILISATION</p>
                        </Link>
                        <Link onClick={closeMenu} to='/privacy'><p>POLITIQUE DE CONFIDENTIALITÉ</p></Link>
                        <Link onClick={closeMenu} to="/refund">
                            <p>CONDITIONS DE RETOUR/REMBOURSEMENT</p>
                        </Link>
                        <Link onClick={closeMenu} to="/sending">
                            <p>CONDITIONS D’ENVOI</p>
                        </Link>
                        <Row id='copyright' className={"footerMobile"}>
                            <div>
                                <img  data-placeholder-background="grey" className={"img-payment lazyload"} data-aspectratio="5.3863636"
                                      data-sizes="auto" width="0" height="0"
                                      data-src={"https://cdn.shopify.com/s/files/1/0597/7420/6125/files/moyen_de_paiement.png?v=1630391768"} alt={"Moyen de paiements"} />
                            </div>
                            <div className={"footerMobile-copyright text-center weight-light"}>
                                Copyright © 2020 F/x/
                            </div>
                        </Row>
                    </Row>
                </div>

            );
        }

        let classes = "";
        if (index === 3) {
            classes = "bmLastItem top-45";
        } else if (index === 4) {
            classes = "bmLastItem";
        }

        return <Link className={classes} key={`renderItemsBurgerMenu-${index}`}
                     onClick={closeMenu} id={item.title}
                     to={item.link}>{item.title}</Link>;
    }

    return (
        <div id={"header"} className="sticky">
            <Container className={"mobile-container"}>
                <div className="d-none d-sm-block">
                    <Row className="header">
                        <Col sm={{span: 2, offset: 4}}>
                            <Link to="/">
                                <img className="fxLogo lazyload" data-placeholder-background="grey"
                                     data-sizes="auto" width="84" height="86" data-src={"/assets/fx.svg"} alt="logo"/>
                            </Link>
                        </Col>

                        <Col sm={6}>
                            <Row className={"bottom-3-percent"}>
                                {menuDataFirstRow.map((item, index) => {
                                    return renderRowMenu(item, index)
                                })}
                            </Row>
                            <Row className={"bottom-3-percent"}>
                                {menuDataSecondRow.map((item, index) => {
                                    return renderRowMenu(item, index)
                                })}
                                <Col sm={1}>
                                    <img className="margin-auto facebook-logo cursor-pointer" onClick={() => {
                                        window.open("https://www.facebook.com/fx.vetements", '_blank')
                                    }} src={"/assets/fb.svg"} alt="facebook-logo" width={8.13} height={15}/>
                                </Col>
                            </Row>
                            <Row>
                                {menuDataThirdRow.map((item, index) => {
                                    return renderRowMenu(item, index)
                                })}
                                <Col sm={1}>
                                    <img className="margin-auto instagram-logo cursor-pointer" onClick={() => {
                                        window.open("https://www.instagram.com/fx.vetements/?hl=fr", '_blank')
                                    }} src={"/assets/insta.svg"} alt="instagram-logo" width={15} height={15}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row id='copyright' className={"footerMobile d-none width-100-vw  d-md-block d-lg-none"}>
                        <Col className={"width-100-vw text-center weight-light"}>
                            Copyright © 2020 F/x/
                        </Col>
                    </Row>
                </div>
                <div
                    className="d-block d-sm-none">
                    <Container fluid className="hamburgerWrapper">
                        {
                            !isOpen ?
                                <img className={"bmBurgerButton"} onClick={openMenu} alt="burger-icon"
                                     src={"/assets/hamburger.svg"}/> :
                                <img className={"bmCrossButton"} onClick={closeMenu} alt="cross-icon"
                                     src={"/assets/x.svg"}/>
                        }
                        <Menu
                            right
                            noOverlay
                            isOpen={isOpen}
                            customCrossIcon={false}
                            customBurgerIcon={false}
                            disableAutoFocus>
                            {
                                menuDataMobile.map((item, index) => {
                                    return renderItemsBurgerMenu(item, index);
                                })
                            }
                        </Menu>
                        <Link to="/" className="fxLogoMobile">
                            <img className="fxLogoMobile lazyload" data-placeholder-background="grey"
                                 data-aspectratio="1"
                                 data-sizes="auto" width="0" height="0" data-src={"/assets/fx.svg"} alt="logo"/>
                        </Link>
                        <Link className={"relative"} onClick={closeMenu} to="/cart">
                            <img className={"zindex-1200 cart-mobile-button"} id="cart" alt="cart"
                                 src={"/assets/cart.svg"}/>
                            <span className={"notif"}><span className="num">{getNumberCart()}</span></span>
                        </Link>
                    </Container>
                </div>
            </Container>
        </div>
    );
}
