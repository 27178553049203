import React, { useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Refund() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Container>
            <Col className="margin-top-30 weight-light">
                <p className='weight-regular'>CONDITIONS DE RETOUR/REMBOURSEMENT</p>
                <p>Merci de commander sur notre boutique en ligne ! Si l’article ne vous convient pas ou qu’il est défectueux, vous pouvez retourner votre commande dans les 14 jours suivant la date de livraison.<br></br> Le/les articles que vous choisissez de retourner peuvent être remboursés. Notez que les frais d’expédition sont des coûts que nous encourons et ne sont donc pas remboursables.</p>
                <p>Vous pouvez renvoyer votre/vos article(s) à l’aide de l’étiquette de retour que nous vous fournirons par mail à la suite de votre demande. Placez l’étiquette de retour sur le colis, et envoyez-le via un point de service avec les moyens de transport mentionnés sur l’étiquette. Une fois que nous aurons reçu le colis, votre retour sera traité. Nous ne pouvons pas vous proposer d’échange, les retours seront donc remboursés.</p>
                <p>Retour gratuit pour les pays suivants :<br />- France</p>
            <p>Quelques critères importants pendant la procédure de retour :<br />
                - Assurez-vous que l’article retourné est toujours en parfait état : pas de défauts, pas de taches, non lavé et non porté.<br />
                    - Assurez-vous que le(s) article(s) est/sont emballé(s) dans un sac ou une boîte étanche avec du ruban adhésif.<br />
                    - Veuillez conserver une preuve d’envoi. En cas de perte de l’envoi, il reste une preuve que le retour a été expédié.<br />
                    Les articles qui nous sont retournés dans un état non vendable, ne seront pas remboursés et pourront vous être renvoyés. Nous nous réservons le droit de refuser tout retour qui ne correspond pas à la politique de retour. Le retour et la responsabilité financière vous incombent jusqu’à ce qu’il parvienne à Fx et soit confirmé par une confirmation de livraison.</p>
                    <p>Tout autre pays qui est exclu de notre liste d’étiquettes de retour gratuites, peut retourner aux frais du client. Veuillez envoyer le retour à l’adresse suivante :<br/><br/>
                        Félix Girard<br />
                    4 avenue de la valtaiserie, 44000 NANTES, Pays de la loire, FRANCE</p><br/>
                                      <p>Pour toute demande de retour, contactez-nous à l’adresse suivante fx.vetements@gmail.com</p>
          
                        </Col>
        </Container>       
                )    
}