import React from "react"
import {NavLink} from "react-router-dom";

export default function MenuButton(props) {
    const { item } = props;
    return (
        <NavLink className="menuItem" style={props.style} activeStyle={{
            fontFamily: "Usual Regular"
        }} exact to={item.link} onClick={props.onClick}>
            <div title={item.title}>{item.title}</div>
        </NavLink>
    )
}
