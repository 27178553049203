import React, {useEffect, useState} from 'react';
import Products from './Products';
import {Carousel, Col, Container, Row} from "react-bootstrap";

export default function Home(props) {
    const [logo, setLogo] = useState("");

    useEffect(() => {
        const element = document.createElement("video");
        // Check if browser support transparent WebM video else we take the gif
        if (!!element.canPlayType) {
            const webm = element.canPlayType('video/webm; codecs="vp8, vorbis"').replace(/^no$/, '');
            if ((webm === 'maybe') || (webm === 'probably')) {
                setLogo(
                    <video autoPlay={true} loop={true} muted={true}>
                        <source src="https://cdn.shopify.com/s/files/1/0597/7420/6125/files/fx.webm?v=1628242479"
                                type="video/webm"/>
                    </video>
                );
            } else {
                insertGif();
            }
        }
    }, []);

    const insertGif = () => {
        setLogo(
            <img data-aspectratio="1" data-sizes="auto" width="0" height="0"
                 data-src="https://cdn.shopify.com/s/files/1/0597/7420/6125/files/fx.gif?v=1628183470" alt={"Fx Logo"}
                 className={"lazyload"}/>
        );
    };

    return (
        <Container id={"home"}>
            <Container className={"d-none d-md-block relative vh-100"}>
                <Row className={"d-none d-md-flex top-30 align-items-center relative"}>
                    <Col sm={{span: 8, offset: 2}} xxl={{span: 10, offset: 1}} className={"d-none d-sm-inline-block"}>
                        <Carousel controls={false} interval={3000} indicators={true}>
                            <Carousel.Item>
                                <img
                                    data-placeholder-background="grey"
                                    className="d-block w-100 lazyload lazypreload"
                                    data-aspectratio="1.77722772" data-sizes="auto" width="0" height="0"
                                    data-src={"https://cdn.shopify.com/s/files/1/0597/7420/6125/files/photo_home_carousselle1.jpg?v=1628178005"}
                                    alt={`Pull Fx-Vetements bob patch champ`}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    data-placeholder-background="grey"
                                    className="d-block w-100 lazyload lazypreload"
                                    data-aspectratio="1.77722772" data-sizes="auto" width="0" height="0"
                                    data-src={"https://cdn.shopify.com/s/files/1/0597/7420/6125/files/photo_home_carousselle2.jpg?v=1628178005"}
                                    alt={`Pull Fx-Vetements patch individu-le shoot`}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    data-placeholder-background="grey"
                                    className="d-block w-100 lazyload lazypreload"
                                    data-aspectratio="1.77722772" data-sizes="auto" width="0" height="0"
                                    data-src={"https://cdn.shopify.com/s/files/1/0597/7420/6125/files/photo_home_carousselle3.jpg?v=1628178005"}
                                    alt={`Pull Fx-Vetements patch old-fashion effroi`}
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                    <Col sm={{span: 2}} xxl={1} className={"d-none d-sm-inline-block animate-logo"} id={"animate-logo"}>
                        {logo}
                    </Col>
                    <Col sm={{span: 4, offset: 1}} xxl={{span: 4, offset: 0}} className={"d-none d-sm-inline-block description-home"}>
                        Vêtements<br/>& patchs d'art<br/>interchangeables
                    </Col>
                </Row>
            </Container>
            <Products
                setCheckout={props.setCheckout}
                client={props.client}
                checkout={props.checkout}
                addVariantToCart={props.addVariantToCart}
            />
        </Container>
    );
}
