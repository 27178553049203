import React, { useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Sending() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Container>
            <Col className="margin-top-30 weight-light">
                <p className='weight-regular'>CONDITIONS D’ENVOI</p><br/>
                <p>Tous nos commandes sont expédiés via les services de LA POSTE ou MONDIAL RELAY.</p>
                <p>Les frais de port sont à vos frais, les tarifs sont indiqué lorsque vous procédé au paiement.</p>
                <p>Les commandes sont traitées sous 20 jours maximum, à compter du jour suivant la validation de la commande.</p>
                <p>Les délais indiqués sont des délais moyens et correspondent aux délais de traitement, de préparation et d’expédition de la commande.</p>
                <p>Veuillez noter que certains retards peuvent survenir pendant les soldes, les périodes très chargées et les jours fériés. </p>
                <p>Pour tous les clients en dehors de l’UE : Les droits de douane, les taxes, les droits et les frais de recouvrement peuvent encore s’appliquer. Tous les droits et/ou taxes sont à la charge du client et ne sont pas collectés par nous, ni inclus dans le total de la commande.</p><br/>
                <p>Statut de la livraison</p>
                <p>Les commandes ne sont pas expédiées ou livrées les week-ends et les jours fériés. Si une livraison subit un retard ou si un article est en rupture de stock, vous en serez informé par e-mail le plus rapidement possible. Au plus tard 30 jours après la commande.</p><br/>
                <p>Disclaimer</p>
                <p>Fx ne garantit pas que tous les articles indiqués soient en stock au moment de votre achat. Si un article est indisponible pour quelquonque raison que ce soit, Fx vous contactera par e-mail. Bien que nous fassions de notre mieux pour afficher des images, des couleurs, des informations et des prix corrects, des erreurs peuvent se produire.</p>
                

            </Col>
        </Container>  
    )    
}