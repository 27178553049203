import React, {useState} from "react"
import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import {toast} from "react-toastify";
import axios from 'axios'

export default function Contact(props) {
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const [command, setCommand] = useState("");
    const [msg, setMsg] = useState("");

    const mailError = () => {
        toast.error(" ✗ Un problème est survenu, merci de rééssayer ou nous contacter à fx.vetements@gmail.com", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const send = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(false);
            return;
        }

        setValidated(true);
        if ([form.form[0], form.form[1], form.form[2], form.form[3]].reduce((bool, input) => {
            return bool && input.validity.valid
        }, true)) {
            setIsLoading(true);
            axios.post("/sendMail", {
                name,
                msg,
                mail,
                command
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((result) => {
                setIsLoading(false);
                if (result.status === 200) {
                    toast.success(" ✓ Nous avons reçu votre demande, nous allons vous répondre dans les plus brefs délais", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (result.status === 418) {
                    toast.warning(" ⚠️ Des paramètres obligatoires (*) ne sont pas renseignés", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    console.error(result.data.error);
                    mailError();
                }
            }).catch((e) => {
                setIsLoading(false);
                console.error(e);
                mailError();
            });
        }
    };

    return (
        <Container>
            <Row className={"top-60"}>
                <Col sm={{span: 4, offset: 1}} xs={{span: 12, offset: 0}} className={"text-left contact top-5-vh"}>
                    <h4>
                        COMMENT POUVONS-NOUS VOUS AIDER
                    </h4>
                    <p className={"weight-light top-5-vh"}>
                        Que vous ayez une question sur votre commande, les produits, l'expédition et les retours, le paiement ou tout autre chose, veuillez nous en informer.
                    </p>
                    <p className={"weight-light"}>
                        Remplissez le formulaire de contact ou envoyez-nous simplement un e-mail à
                        fx.vetements@gmail.com, et nous vous répondrons dans les plus brefs délais.
                    </p>
                </Col>
                <Col sm={{span: 5, offset: 0}} xs={{span: 12, offset: 0}} className={"top-5-vh"}>
                    <Row>
                        <h4>
                            ENVOYEZ NOUS UN MESSAGE
                        </h4>
                        <div className={"weight-light top-5-vh"}>
                            <Form validated={validated} onSubmit={send}>

                                <FloatingLabel
                                    controlId="name"
                                    label="* NOM Prénom"
                                    className={"font-size-080 weight-light"}
                                >
                                    <Form.Control required className={"font-size-125 weight-light"} type="text"
                                                  placeholder="NOM Prénom" value={name}
                                                  onChange={(e) => setName(e.target.value)}/>
                                    <Form.Control.Feedback tooltip type="invalid" className={"font-size-100"}>
                                        Merci de renseigner votre nom et prénom
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="mail"
                                    label="* Email"
                                    className={"top-15 font-size-080 weight-light"}
                                >
                                    <Form.Control required className={"font-size-125 weight-light"} type="email"
                                                  placeholder="Email" value={mail}
                                                  onChange={(e) => setMail(e.target.value)}/>
                                    <Form.Control.Feedback tooltip type="invalid" className={"font-size-100"}>
                                        Merci de renseigner votre email
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="command"
                                    label="Numéro de commande"
                                    className={"top-15 font-size-080 weight-light"}
                                >
                                    <Form.Control className={"font-size-125 weight-light"} type="text"
                                                  placeholder="Numéro de commande" value={command}
                                                  onChange={(e) => setCommand(e.target.value)}/>
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="message"
                                    label="* Votre message ici..."
                                    className={"top-15 font-size-080 weight-light"}
                                >
                                    <Form.Control required as="textarea"
                                                  className={"font-size-125 weight-light textarea"}
                                                  placeholder="Votre message ici..." value={msg}
                                                  onChange={(e) => setMsg(e.target.value)}/>
                                    <Form.Control.Feedback tooltip type="invalid" className={"font-size-100"}>
                                        Merci de formuler votre demande
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                                <Button className={"top-15 send-button button-size"}
                                        onClick={!isLoading && send} variant={"dark"} disabled={isLoading}>
                                    {isLoading ? 'Envoi en cours…' : 'ENVOYER'}
                                </Button>
                            </Form>
                        </div>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
