import React from "react"
import {Col, Container, Row} from "react-bootstrap";

export default function About(props) {
    return (
        <Container>
            <Row className={"top-60 text-left"}>
                <Col sm={{span: 8, offset: 2}} xs={12}>
                    <p className={"weight-light top-5-vh"}>
                        F/x/ est une jeune marque indépendante française créée en 2020. Notre objectif, nous développer
                        dans un idéal de slow fashion. Au fils du temps et de notre développement, nous nous efforcerons
                        à produire de manière toujours plus propre et responsable.
                    </p>
                    <p className={"weight-light"}>
                        « Notre première collection a été produite grâce à un financement participatif, nous tenons à
                        remercier
                        toutes les différentes personnes qui nous ont soutenu dans ce projet quel que soit leurs
                        investissements,
                        si ce projet existe, c’est aussi grâce à vous. Merci pour vos dons et pour votre confiance :
                        <br/><br/>
                        <span className={"weight-regular"}>
                            Léo Mérand, Coline Girard, Arthur Courveaulle, Charlotte Gicquel, David Courveaulle, Magali
                            Courveaulle, Mauda Ayandho, Elsie-Ann Jaunet, Carl Saillard, Arthur Gicquel, Charlotte
                            Beaudouin, Theophile Hubert, Etienne Bourgault, Jannick Aury, Françoise Girard, Caroline
                            Champenois, Jules Pinot Akkawi, Cindy Guillotte, Alexis Emonet, Fred Bonnet, Simon Menard,
                            Emmanuele Varez, Francoise Crouton, Corinne Courveaulle, Greg Caquineau, Limbi Ayandho,
                            Timothée Chevet, Brigitte Loewenstein, Alix Laurent, Monique Courveaulle, Simon Plumecocq,
                            Julie Bertrais, Marie Bourtayre, Eddy Guilbaud, Margaux Courveaulle, Stéphanie Girard,
                            Sylvain Kerjose, Catherine Jaunet, Amandine Varez, Odeline Bassin, Romain Sirotteau, Annaig
                            kerangueven, Thomas Gilet, Isabelle Vigier, Sidonie Vigier, Noé Lebert, Bastien Otin,
                            François Jousset, Jean-Baptiste Castellani, Vincent Peyres, Alicia Leclair, Coline Pivert,
                            Alexis Jacob, Corentin Desport, Armand Ollier, Corentin Farcy, Romane Girard, Béatrice
                            Huvelie, Martin Augereau, Pierre Lescop, Antoine Chateauneuf, Lubin Japel, Gabriel Aury,
                            Carlos Trindade, Felix Busson, Alexandre Anjolras, Bastien Vallee, Romain Jaunet,
                            Charles-Antoine Attia, Lucile Gestin, Louise Guiller, Marc-Antoine Blanchet, Lucile Gestin,
                            Agathe Bidault.
                        </span>
                        <br/><br/>
                        Un grand merci à nos deux développeurs Charles-Antoine Attia & Alexandre Robin, pour leur
                        excellent travail. »
                    </p>
                    <p className={"float-right"}>
                        F/x/ team
                    </p>
                </Col>
            </Row>
        </Container>
    )
}
