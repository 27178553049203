import React, { useEffect, useState } from 'react';
import Home from "./components/Home";
import Cart from "./components/Cart";
import Header from "./components/Header";
import Contact from "./components/Contact";
import LookBook from "./components/Lookbook";
import { Route, Switch } from "react-router";
import { ToastContainer } from "react-toastify";
import About from "./components/About";
import Footer from "./components/Footer";
import CGU from "./components/CGU";
import Refund from './components/Refund';
import Sending from './components/Sending';
import Privacy from './components/Privacy';
import 'lazysizes';
import { getBuilder } from "./utils/clientBuilder";

export default function App(props) {
    const [checkout, setCheckout] = useState({ lineItems: [] });
    const [client, setClient] = useState(undefined);
    const windowExists = typeof window !== undefined;
    const regex = /^(.+)(\..{2,4})(\?v=.+)$/;
    let innerWidth;
    let innerHeight;

    useEffect(() => {
        const lastCheckout = window.localStorage.getItem("checkoutFxVetements");
        if (lastCheckout) {
            client && client.checkout.fetch(lastCheckout).then((checkout) => {
                if(checkout.completedAt) {
                    createCheckout();
                } else {
                    setCheckout(checkout);
                }
            }).catch(() => {
                createCheckout();
            });
        } else {
            createCheckout();
        }
    }, [client]);

    useEffect(() => {
        onOrientationChange();
        if (windowExists) {
            setClient(getBuilder());
            innerHeight = window.innerHeight;
            innerWidth = window.innerWidth;
            window.addEventListener("orientationchange", onOrientationChange);
            window.addEventListener('resize', resizeAllLazyLoaded);
            window.addEventListener("lazybeforesizes", addSizeImg);
            return () => {
                window.removeEventListener("orientationchange", onOrientationChange);
                window.addEventListener('resize', resizeAllLazyLoaded);
                window.removeEventListener("lazybeforesizes", addSizeImg);
            };
        }
    }, [windowExists]);

    const resizeAllLazyLoaded = (e) => {
        const imgs = document.getElementsByClassName("lazyloaded");
        for (const img of imgs) {
            if (img.getAttribute("fakesrc")) {
                img.removeAttribute("width");
                img.removeAttribute("height");
            }
        }
    };

    const addSizeImg = (e) => {
        const src = e.target.dataset.src;
        const matched = src.match(regex);
        const width = e.detail.width;
        const height = Math.round(e.detail.width / e.target.dataset.aspectratio);
        const widthRatio = Math.round(width * (window.devicePixelRatio || 1));
        const heightRatio = Math.round(height * (window.devicePixelRatio || 1));
        if (e.target.getAttribute("width") === "0") {
            e.target.setAttribute("width", width);
            e.target.setAttribute("height", height);
        }
        if (matched && !e.target.getAttribute("fakesrc")) {
            e.target.setAttribute("fakesrc", src);
            e.target.dataset.src = `${matched[1]}_${widthRatio}x${heightRatio}${matched[2]}${matched[3]}`;
        }
    };

    const mobileCheck = () => {
        let check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series([46])0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br([ev])w|bumb|bw-([nu])|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do([cp])o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly([-_])|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-([mpt])|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([ \-\/])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja([tv])a|jbro|jemu|jigs|kddi|keji|kgt([ \/])|klon|kpt |kwc-|kyo([ck])|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t([- ov])|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30([02])|n50([025])|n7(0([01])|10)|ne(([cm])-|on|tf|wf|wg|wt)|nok([6i])|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c([-01])|47|mc|nd|ri)|sgh-|shar|sie([-m])|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel([im])|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c([- ])|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4))) check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    };

    const onOrientationChange = () => {
        if (windowExists && mobileCheck() && Math.abs(window.orientation) === 90) {
            document.getElementById("root").className = "blur";
            document.ontouchmove = e => {
                e.preventDefault()
            };
            document.getElementById("block-warning-message").className = "displayed";
        } else {
            document.getElementById("root").className = "";
            document.ontouchmove = () => true;
            document.getElementById("block-warning-message").className = "";
        }
    };

    const createCheckout = () => {
        client && client.checkout.create().then((res) => {
            setCheckout(res);
            window.localStorage.setItem("checkoutFxVetements", res.id);
        });
    };

    const updateCheckout = (checkout) => {
        setCheckout(checkout);
        window.localStorage.setItem("checkoutFxVetements", checkout.id);
    };

    const updateQuantityInCart = (lineItemId, quantity) => {
        const checkoutId = checkout.id;
        const lineItemsToUpdate = [{ id: lineItemId, quantity: parseInt(quantity, 10) }];

        return client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
            updateCheckout(res);
        });
    };

    const removeLineItemInCart = (lineItemId) => {
        const checkoutId = checkout.id;

        return client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
            updateCheckout(res);
        });
    };

    return (
        <>
            <div className={"height-full"}>
                <div className={"padding-bottom-100"}>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <Header checkout={checkout} client={client} />
                    <Switch>
                        <Route exact path={["/", "/shop"]}>
                            <Home checkout={checkout} setCheckout={updateCheckout} client={client} />
                        </Route>
                        <Route path="/cart">
                            <Cart checkout={checkout} setCheckout={updateCheckout}
                                updateQuantityInCart={updateQuantityInCart}
                                removeLineItemInCart={removeLineItemInCart} client={client} />
                        </Route>
                        <Route path="/contact">
                            <Contact />
                        </Route>
                        <Route path="/lookbook">
                            <LookBook />
                        </Route>
                        <Route path="/about">
                            <About />
                        </Route>
                        <Route path="/cgu">
                            <CGU />
                        </Route>
                        <Route path="/refund">
                            <Refund />
                        </Route>
                        <Route path="/sending">
                            <Sending />
                        </Route>
                        <Route path="/privacy">
                            <Privacy />
                        </Route>
                    </Switch>
                </div>
                <Footer />
            </div>
        </>
    );
}
