import React from 'react';
import {Col, Row} from "react-bootstrap";

export default function LineItem(props) {

    const decrementQuantity = (lineItemId) => {
        const updatedQuantity = props.lineItem.quantity - 1;
        props.updateQuantityInCart(lineItemId, updatedQuantity);
    };

    const incrementQuantity = (lineItemId) => {
        const updatedQuantity = props.lineItem.quantity + 1;
        props.updateQuantityInCart(lineItemId, updatedQuantity);
    };

    if(!props.lineItem.title || !props.lineItem.variant) {
        props.removeLineItemInCart(props.lineItem.id);
        return null;
    }

    return (
        <Row>
            <Col sm={{span: 2, offset: 1}} xs={{span: 2}}>
                {props.lineItem?.variant?.image ?
                    <img data-placeholder-background="grey" className='lazyload' data-aspectratio="0.894428152492669"
                         data-sizes="auto" width="0" height="0" data-src={props.lineItem.variant.image.src} alt={`${props.lineItem.title}`}/> : null}
            </Col>
            <Col className={"margin-auto"} sm={{span: 9}} xs={{span: 10}}>
                <Row>
                    <Col sm={{span: 6}} xs={{span: 9}}>
                        <span> {props.lineItem.title} </span>
                        <div className={"top-5"}>{props.lineItem.variant.title}</div>
                        <div className={"top-5"}>{parseFloat(props.lineItem.variant.price.amount).toFixed(2)} €</div>
                    </Col>
                    <Col sm={{span: 3}} xs={{span: 3}}>
                        <Row>
                            <div>
                                <span className={"cursor-pointer "}
                                      onClick={() => decrementQuantity(props.lineItem.id)}>-  </span>
                                <span>{props.lineItem.quantity}</span>
                                <span className={"cursor-pointer"}
                                      onClick={() => incrementQuantity(props.lineItem.id)}>  +</span>
                            </div>
                        </Row>
                        <Row>
                            <span className={"cursor-pointer top-5"}
                                  onClick={() => props.removeLineItemInCart(props.lineItem.id)}>
                                Retirer
                            </span>
                        </Row>
                    </Col>
                    <Col sm={{span: 3}} className={"d-none d-sm-block"}>
                        <span>{(props.lineItem.quantity * props.lineItem.variant.price.amount).toFixed(2)} €</span>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
