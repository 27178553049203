import React, {useEffect} from 'react';
import {Col} from "react-bootstrap";

export default function Product(props) {
    const showModalSentProduct = () => {
        props.setFocusedProduct(props.product);
    };

    useEffect(() => {
        document.addEventListener('lazyloaded', scroll);

        return () => {
            document.removeEventListener('lazyloaded', scroll);
        }
    }, [props.hasScrolled]);

    const scroll = () => {
        if (!props.hasScrolled) {
            props.scrollToUrl();
            props.setHasScrolled(true);
        }
    };

    return (
        <Col className={"product"} onClick={showModalSentProduct}>
            {props.product.images.length ?
                <img data-placeholder-background="grey" className='lazyload' data-aspectratio="0.894428152492669"
                     data-sizes="auto" width="0" height="0" data-src={props.product.images[0].src}
                     alt={`${props.product.title}`}/> : null}
            <div>
                <span>{props.product.title}</span>
            </div>
            <div>
                {props.product && props.product.variants && props.product.variants.map((variant, index) => {
                    let size = <span
                        className={!variant.available ? "line-through" : ""}>{index ? ` - ${variant.title}` : variant.title}</span>;
                    return <span
                        key={`indexProduct-${index}`}
                        className={"weight-light"}>
                        {size}
                    </span>
                })}
            </div>
            <div>
                <span className={"weight-light-italic"}>{parseFloat(props.product.variants[0].price.amount).toFixed(2)} €</span>
            </div>
        </Col>
    );
}
