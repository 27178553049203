import React from "react"
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function Footer() {
    return (
        <Container fluid className={" d-none d-sm-block"}>
            <div className='footer'>
                <Row className={'padding-top-2'}>
                    <Col sm={2}>
                        <img className="margin-auto cursor-pointer" onClick={() => {
                            window.open("https://www.facebook.com/fx.vetements", '_blank')
                        }} src={"/assets/fb.svg"} alt="facebook-logo"/>
                        <img className="margin-auto padding-top-5 cursor-pointer" onClick={() => {
                            window.open("https://www.instagram.com/fx.vetements/?hl=fr", '_blank')
                        }} src={"/assets/insta.svg"} alt="instagram-logo"/>
                    </Col>
                    <Col sm={3}>
                        <Link to='/cgu'>
                            <p>CONDITIONS D’UTILISATION</p>
                        </Link>

                        <Link to='/privacy'><p>POLITIQUE DE CONFIDENTIALITÉ</p></Link>
                    </Col>
                    <Col sm={3}>
                        <Link to="/refund">
                            <p>CONDITIONS DE RETOUR/REMBOURSEMENT</p>
                        </Link>
                        <Link to="/sending">
                            <p>CONDITIONS D’ENVOI</p>
                        </Link>
                    </Col>
                    <Col sm={{span: 3}} className={"vertical-align-col"}>
                        <img data-placeholder-background="grey" className={"img-payment width-half lazyload"}
                             data-aspectratio="5.3863636"
                             data-sizes="auto" width="0" height="0"
                             data-src={"https://cdn.shopify.com/s/files/1/0597/7420/6125/files/moyen_de_paiement.png?v=1630391768"}
                             alt={"Moyen de paiements"}/>
                    </Col>
                </Row>
                <Row className={" width-100-vw"}>
                    <Col className={"width-100-vw text-center weight-light"}>
                        Copyright © 2020 F/x/
                    </Col>
                </Row>
            </div>

        </Container>
    )
}
