import React from 'react';
import { Col, Container, Row } from "react-bootstrap";

export default function LookBook() {
    return (
        <Container className={"top-60"}>
            <Row>
                <Col className="d-none d-sm-block" sm>
                    <img alt="Concept pull et patch champ" data-placeholder-background="grey" className={"lazyload"} data-aspectratio="0.56152513"
                         data-sizes="auto" width="0" height="0"
                         data-src={"https://cdn.shopify.com/s/files/1/0597/7420/6125/files/girl.jpg?v=1628182109"}/>
                </Col>
                <Col className="d-block d-sm-none">
                    <img alt="Concept pull et patch champ" data-placeholder-background="grey" className={"lazyload"} data-aspectratio="1.18181818"
                         data-sizes="auto" width="0" height="0"
                         data-src={"https://cdn.shopify.com/s/files/1/0597/7420/6125/files/bboy_patch.jpg?v=1628182681"}/>
                </Col>
                <Col className="top-60 weight-light" sm>
                    <p>
                        Passionnés par les vêtements et les arts graphiques,
                        nous avons créé une marque mêlant nos centres d’intérêts
                        tout en apportant une vision nouvelle à ces deux domaines.
                        Cette vision, c’est F/x/, un clothing concept proposant
                        des vêtements personnalisables grâce à des patchs d’art.
                    </p>
                    <p className="top-30">
                        Dans l’inconscient collectif, une œuvre d’art appartient
                        à un musée, à un cadre, une stèle… Notre but est de faire vivre
                        différentes formes d’art (street art, photographie, typographie…)
                        par le biais de nos vêtements/patchs.
                        <br />
                        Les patchs ne sont plus de simples accessoires,
                        mais des supports d’œuvres, nos vêtements transcendent
                        leur nature d’habits pour devenir des galeries.
                    </p>
                    <p className="top-30">
                        Nous vous proposons des vêtements sobres aux finitions soignées
                        qui s’accorderont facilement à vos différentes tenues.
                        L’idée c’est de vous proposer des indispensables
                        de qualité afin qu’ils durent dans le temps, tout en pouvant
                        les rendre uniques et différents chaque jour grâce à nos différents
                        patchs fixés sur velcro que vous pouvez interchanger rapidement.
                    </p>
                    <p className="top-30">
                        À nos yeux, nos patchs sont de véritables œuvres d’art,
                        c’est pour cela que nous vous recommandons,
                        lorsque vous ne les portez pas, de les exposer chez vous.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className="d-none d-sm-block fieldPatch" sm={2}>
                    <img alt="Patch champ" className={"lazyload"} data-aspectratio="0.718390804597701" data-sizes="auto" width="0" height="0"
                         data-src={"https://cdn.shopify.com/s/files/1/0597/7420/6125/files/patch.jpg?v=1628183087"}/>
                </Col>
                <Col className="d-block d-sm-none">
                    <img alt="Patch champ patch old-fashion effroi " data-placeholder-background="grey" className={"lazyload"}
                         data-aspectratio="1.120689655172414" data-sizes="auto" width="0" height="0"
                         data-src={"https://cdn.shopify.com/s/files/1/0597/7420/6125/files/girl_patch.jpg?v=1628182681"}/>
                </Col>
            </Row>
            <Row>
                <Col className="d-none d-sm-block earv" xs={12} sm={{ span: 5, offset: 7 }}>
                    <img alt="Concept pull manche brodé Fx" data-placeholder-background="grey" className={"lazyload"} data-aspectratio="0.800693240901213"
                         data-sizes="auto" width="0" height="0"
                         data-src={"https://cdn.shopify.com/s/files/1/0597/7420/6125/files/bboy.jpg?v=1628182109"}/>
                </Col>
                <Col className="d-block d-sm-none">
                    <img alt="Concept pull noir patch vis-a-vis incendie" data-placeholder-background="grey" className={"lazyload"}
                         data-aspectratio="0.884353741496599" data-sizes="auto" width="0" height="0"
                         data-src={"https://cdn.shopify.com/s/files/1/0597/7420/6125/files/boy_girl.jpg?v=1628182681"}/>
                </Col>
            </Row>
            <Row>
                <Col className="d-none d-sm-block medley" xs={12} sm={12}>
                    <img alt="Concept multi patchs" data-placeholder-background="grey" className={"lazyload"} data-aspectratio="0.878682842287695"
                         data-sizes="auto" width="0" height="0"
                         data-src={"https://cdn.shopify.com/s/files/1/0597/7420/6125/files/medley.jpg?v=1628182109"}/>
                </Col>
                <Col className="d-block d-sm-none">
                    <img alt="Concept pull noir patch individu-le shoot" data-placeholder-background="grey" className={"lazyload"}
                         data-aspectratio="1.077348066298343" data-sizes="auto" width="0" height="0"
                         data-src={"https://cdn.shopify.com/s/files/1/0597/7420/6125/files/boy_dog_patch.jpg?v=1628182681"}/>
                </Col>
            </Row>
        </Container>
    )
}
